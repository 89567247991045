td{
    font-size: 14px;

    &:hover{
        cursor: pointer;
    }
}

th{
    vertical-align: middle;
    font-size: 16px;
    i{
        line-height: 35px;
        vertical-align: middle;
    }
    &:hover{
        cursor: pointer;
    }
}