.projects {
  h1 {
    padding-top: 48px;
  }
}

.project {
  h4 {
    min-height: 42px;
  }

  &:first-of-type .partner-spacing {
    margin-bottom: 0;
  }
}

.project-information {
  h2 {
    margin-bottom: 24px;
  }

  h3 {
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 500;
  }

  ul {
    list-style: none;
    padding-left: 0;
    font-size: 16px;

    li {
      margin-bottom: 8px;
      opacity: 0.8;
    }
  }
}

.project-content {
  margin-bottom: 24px;

  h2 {
    margin-bottom: 24px;
  }

  p {
    white-space: pre-line;

    a{
      text-decoration: underline;
      color: #1ca6f4;
    }
  }

  img,
  video {
    max-width: 100%;
  }

  video {
    max-height: 560px;
  }
}

.carousel-caption {
  top: 100%;
  bottom: auto;
  transform: none;
  color: black;
  text-align: left;
  position: relative;
  right: auto;
  left: auto;
}

.carousel-indicators {
  bottom: 80px;
}


p.related-project {

    margin:0;
  
    a{
    color: #888;
    text-decoration: underline;
  }
}