.home-header {
  color: white;
  font-weight: 200;
  letter-spacing: 1px;
  text-shadow: 0 0 6px rgba(0, 0, 0, 0.6);
  margin-bottom: 6px;
  margin-bottom: 24px;

  h1 {
    max-width: 730px;
    filter: drop-shadow(2px 2px 6px rgba(0, 0, 0, 0.25));
    font-size: 88px;
    letter-spacing: 0.01em;

    &:hover {
      cursor: pointer;

      &:after {
        border-bottom: 1px solid white;
      }
    }
  }

  .carousel-indicators {
    bottom: 20px;
  }
}

.carousel-indicators,
.carousel-control-prev,
.carousel-control-next {
  filter: drop-shadow(2px 2px 6px rgba(0, 0, 0, 0.5));
}

.home-page h1 {
  font-family: 'Big Shoulders Display', cursive !important;
  letter-spacing: 0.05em;
}

.home-page-blog {

  .container {
    position: relative;
  }

  .home-page-blog-block-text-container {
    position: absolute;
    bottom: 37px;
    left: 52px;
    z-index: 1;
    width: 384px;
    margin-bottom: 0;
    background: rgba(255, 255, 255, 0.94);
    padding: 36px 42px;
  }
}

.home-page-about-us-block,
.home-page-projects-block,
.home-page-blog-block {


  h3 {
    a {
      color: #1a1a1a;
      text-decoration: none;
    }
  }
}

.home-page-projects-block {
  background: #fbfbfb;
  border-top: 1px solid #f5f5f5;
  border-bottom: 1px solid #f5f5f5;

  .project-container::before {
    background-color: rgba(0, 0, 0, 0.44);
  }

  h3 a {
    font-weight: 400;
  }

  h3 {
    margin-top: 32px;
    margin-bottom: 16px;
  }
}

.home-page-about-us-block,
.home-page-blog-block {

  text-align: center;
  max-width: 800px;


  h3 {
    margin-top: 32px;
    margin-bottom: 16px;
  }
}

.row.home-page-more-block {

  .our-team,
  .partner {
    padding: 0;
    margin: 0;

    &:after {
      display: block;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.45);
    }

  }
}

.home-page-links {

  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 56px;
  padding-bottom: 56px;

  .home-page-links-container {

    div.home-page-link {
      width: 317px;
      float: left;
      margin: 0;
      padding: 0;
      padding-right: 20px;

      h4 {
        font-size: 20px;
        line-height: 28px;
        margin-bottom: 12px;
        font-weight: bold;
      }

      p {
        min-height: 56px;
      }

      a {
        font-weight: 500;
        position: relative;
        text-decoration: none;

        &:after {
          position: absolute;
          height: 1px;
          left: 0;
          bottom: -4px;
          right: 0;
          content: '';
          display: block;
          background: #337ab7;
        }
      }

      &:last-of-type {
        padding-right: 0;
      }
    }
  }
}

.home-page-training-programs, .home-page-miah {
  background: #fbfbfb;
  border-top: 1px solid #f5f5f5;
  border-bottom: 1px solid #f5f5f5;
}

@media (min-width: 992px) {
  .home-page-training-programs {
    .row {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .home-page-training-programs {
    .home-page-training-programs-text-container {
      padding-left: 64px;

      &.left {
        padding-left: 0;
        padding-right: 64px;
      }
    }
  }
}

@media (max-width: 992px) {

  .carousel-item {
    overflow: hidden;
  }

  .carousel-caption {
    p {
      min-height: 48px;
    }
  }

  .home-header {
    h1 {
      max-width: 400px
    }

    img {
      height: 620px;
      margin: 0 auto;
      max-width: inherit;
      display: inline-block;
      -webkit-transform: translateX(-24%);
      transform: translateX(-24%);
    }

    .carousel-item {
      overflow: hidden;
    }

  }

  .home-page-projects-block {
    .col-xs-12 {
      margin-bottom: 40px;
    }
  }

  .home-page-training-programs {
    img {
      margin: 0 auto;
    }
  }

  .home-page-links {

    padding-top: 0;
    padding-bottom: 0;

    .home-page-links-container div.home-page-link {
      margin-bottom: 40px;

      p {
        min-height: auto;
      }
    }
  }

  .home-page-blog .home-page-blog-block-text-container {
    position: relative;
    top: auto;
    left: auto;
    bottom: auto;
  }
}

.learnmore-section {
  background: #f4f4f4;
  padding: 2em;
  margin: 1e;
  border: 1px solid #eee;

  &:hover {
    background-color: rgba(0, 0, 0, 0.12);

  }
}

@media (max-width: 1160px) {
  .home-header {

    h1 {
      font-size: 64px;
    }

  }
}

@media (max-width: 768px) {
  .home-header {

    h1 {
      font-size: 48px;
      max-width: 340px;
    }

    img {
      height: 420px;
      margin: 0 auto;
      max-width: inherit;
      display: inline-block;
      transform: translateX(-10%);
    }
  }
}

@media (max-width: 480px) {
  .home-header {
    img {
      height: 420px;
      margin: 0 auto;
      max-width: inherit;
      display: inline-block;
      transform: translateX(-10%);
    }
  }
}

@media (max-width: 576px) {
  .home-page{
    .container{
      padding-left: 32px;
      padding-right: 32px;
    }
  }
}