.search.box{
    margin-top: 40px;

    > input{
         margin-left: 8px;
    }
}

.blog-container {
    background-size: cover;
    background-position: center;
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    padding: 0 24px;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 1;
    }

    h4 {
        z-index: 2;
        color: white;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
        text-align: center;
        font-weight: 400;
        letter-spacing: 0.01em;
    }

    &:hover{
        &::before {
            background-color: rgba(0, 0, 0, 0.75);
      
        } 
    }
}

@media (max-width: 991px) {
    .blog-container {
        height: 200px;
    }
}