footer {
    background: #1a1a1a;
    margin-top: 48px;
    padding: 36px 0;
  
    h3, p {
      font-weight: 200;
      color: #ffffff;
    }
  
    h3 {
      text-transform: uppercase;
      font-size: 18px;
    }
  
    p, a {
      font-weight: 300;
      opacity: 0.72;
      color: #ffffff;
      text-decoration: none;
  
      &:hover {
        opacity: 1;
      }
    }
  
    p {
      margin-bottom: 2px;
      font-size: 16px;
      line-height: 1.65em;
    }
  
    a.footer-social-icon {
      margin-right: 10px;
      font-size: 24px;
      margin-top: 20px;
      
    }
  
    .footer-divider {
      margin: 24px 0;
      height: 1px;
      width: 100%;
      content: '';
      display: block;
      background: rgba(255, 255, 255, 0.2);
    }
  }
  
  footer h3 {
    font-weight: 200;
    color: #ffffff;

    margin-top: 24px;
    text-transform: uppercase;
    font-size: 18px;

    &:first-of-type{
       margin-top: 0;
    }
}