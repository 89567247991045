$white: #ffffff;
$transparent-black: rgba(0, 0, 0, 0.72);
$light-white: rgba($white, 0.8);

@mixin center-position {
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
}

.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
}

.ourteam-container {
  position: relative;
  padding: 0 !important;

  .ourteam-content {
    position: absolute;
    opacity: 0;
    @include center-position;

    h4 {
      margin-bottom: 2px;
    }

    .person-title {
      font-style: italic;
      color: $light-white;
      line-height: 1.2em;
      font-size: 16px;
      margin: 0;
    }

    * {
      color: $white;
      text-align: center;
    }
  }

  &:hover {
    opacity: 1;

    .ourteam-content {
      opacity: 1;
    }

    &:before {
      position: absolute;
      content: '';
      display: block;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: $transparent-black;
    }
  }
}

.person {
  &.Alumni img {
    filter: grayscale(100%);
  }
}

@mixin activeFilter {
  opacity: 1;
  font-weight: 600;
  color: #1ca6f4;
  position: relative;

  &:after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 2px;
    left: 0;
    bottom: -4px;
    background-color: #1ca6f4;
  }
}

.project-filters,
.team-filters,
.publication-filters {
  margin-top: 24px;
  margin-bottom: 20px;

  a {
    color: #1a1a1a;
    font-size: 18px;
    opacity: 0.57;
    margin-right: 24px;
    text-decoration: none;

    &.active {
      opacity: 1;
      font-weight: 600;
      color: #1ca6f4;
      position: relative;

      &:after {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 2px;
        left: 0;
        bottom: -4px;
        background-color: #1ca6f4;
      }
    }

    &:hover {
      cursor: pointer;
      text-decoration: none;
    }
  }

  @each $category, $index in (
  'Current': 1, 'Management': 2, 'Team': 3, 'Research': 4, 
  'Technical': 5, 'Faculty': 6, 'Industry': 7, 'Alumni': 8, 
  'Digitization': 2, 'Modelling': 3, 'Fabrication': 4, 'Storytelling': 5
) {
  &.#{$category} {
    a:nth-child(#{$index}) {
      @include activeFilter;
    }
  }

  @if $category == 'Current' {
    &.All {
      a:nth-child(#{$index}) {
        @include activeFilter;
      }
    }
  }
}

}

.blog-filters{
  margin-top: 24px;
  margin-bottom: 20px;

  a {
    color: #1a1a1a;
    font-size: 18px;
    opacity: 0.57;
    margin-right: 24px;
    text-decoration: none;

    &.active {
      opacity: 1;
      font-weight: 600;
      color: #1ca6f4;
      position: relative;

      &:after {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 2px;
        left: 0;
        bottom: -4px;
        background-color: #1ca6f4;
      }
    }

    &:hover {
      cursor: pointer;
      text-decoration: none;
    }
  }

  @each $category,
  $index in ('All': 1, 'Digitization': 2, 'Modelling': 3, 'Fabrication': 4, 'Storytelling': 5) {
    &.#{$category} a:nth-child(#{$index}) {
      @include activeFilter;
    }
  }
}

@media (max-width: 768px) {
  .projects h1{
    padding-top: 20px;
  }

  .search.box{
    margin-top: 20px;
  }

  .project-filters,
  .team-filters,
  .blog-filters,
  .publication-filters {
    a {
      margin-bottom: 12px;
      display: block;
    }

    &.All a:nth-child(1):after,
    &.Digitization a:nth-child(2):after,
    &.Modelling a:nth-child(3):after,
    &.Fabrication a:nth-child(4):after,
    &.Storytelling a:nth-child(5):after,
    &.Storytelling a:nth-child(6):after {
      bottom: -3px;
    }
  }

  .blog-filters

  .blog-filters {
    .pull-left {
      float: none !important;
    }
  }

  .detail-filters {

    .details {
      margin-bottom: 12px;
    }

    .pull-left,
    .pull-right {
      float: none !important;

      margin-top: 12px;
    }
  }
}


