.blogs {
  h4 {
    margin-bottom: 4px;
    min-height: auto;
  }

  p {
    opacity: 0.54;
  }
}

.blog-title {

  span {
    font-size: 16px;
    line-height: 14px;
    color: rgba(0, 0, 0, 0.54);
  }
}

.blog-content {

  img,
  video {
    max-width: 100%;
  }

  video {
    max-height: 560px;
  }

  p{
    a{
      text-decoration: underline;
      color: #1ca6f4;
    }
  }
}

.projects {
  h1 {
    padding-top: 48px;
  }
}

.blog {
  h4 {
    min-height: 42px;
  }

  &:first-of-type .partner-spacing {
    margin-bottom: 0;
  }
}

.blog-information {
  h2 {
    margin-bottom: 24px;
  }

  h3 {
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 400;
  }

  ul {
    list-style: none;
    padding-left: 0;
    font-size: 16px;

    li {
      margin-bottom: 8px;
    }
  }
}

.blog-content {
  margin-bottom: 96px;

  h2 {
    margin-bottom: 24px;
  }

  p {
    white-space: pre-line;
  }

  img,
  video {
    max-width: 100%;
  }

  video {
    max-height: 560px;
  }
}


.carousel-caption {
  top: 100%;
  bottom: auto;
  transform: none;
  color: black;
  text-align: left;
  position: relative;
  right: auto;
  left: auto;
}

.carousel-indicators {
  bottom: 80px;
}
