.desktop-navigation.navbar-fixed {
  top: 0;
  z-index: 100;
  position: fixed;
  width: 100%;
  padding: 8px 0;
}

.desktop-navigation {
  padding: 24px 0;
  background: rgba(0, 0, 0, 1);

  a {
    color: white;
    height: 100%;
    line-height: 48px;
    padding: 0 8px;
    margin-left: 12px;
    text-transform: uppercase;
    text-decoration: none;
    font-size: 14px;

    &.active {
      opacity: 1;
      font-weight: 600;
      color: #1ca6f4;
      position: relative;

      &:after{
        content: '';
        display: block;
        position: absolute;
        width: 90%;
        height: 2px;
        left: 5%;
        bottom: -4px;
        background-color: #1ca6f4;
      }
    }

    &:hover,
    &:focus {
      text-decoration: none !important;
      color: #1ca6f4;
    }
  }
}

.desktop-navigation.navbar-fixed {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000; 
}

body.navbar-fixed {
  padding-top: 76px;
}

body.open {
  overflow: hidden;
}

$dark: #212121;
$light: #efefef;
$color: #b3e5fc;
$nav-links: aboutus, projects, partners, ourteam, blogs, training, publications, cdmica, contact;

@mixin btn($left, $top) {
  width: 60px;
  height: 60px;
  top: $top;
  left: $left;
  transition-duration: 0.5s;
}

@mixin icon($height, $width) {
  transition-duration: 0.5s;
  position: absolute;
  height: $height;
  width: $width;
  top: 30px;
  background-color: $light;
}

@mixin icon-before($height, $width, $top) {
  transition-duration: 0.5s;
  position: absolute;
  width: $width;
  height: $height;
  background-color: $light;
  content: "";
  top: $top;
}

@mixin icon-after($height, $width, $top) {
  transition-duration: 0.5s;
  position: absolute;
  width: $width;
  height: $height;
  background-color: $light;
  content: "";
  top: $top;
}

.mobile-top-bar {
  height: 60px;
  background: black;
  width: 100%;
  z-index: 999;
  position: relative;

  &.open {
    position: fixed;
  }

  img {
    margin-top: 13px;
    margin-left: 24px;
    box-shadow: none;
    outline: none;
  }
}

.mobile-navigation {
  position: absolute;
  left: -100%;
  top: 0;
  width: 75%;
  height: 100%;
  padding-top: 96px;
  background: black;
  z-index: 998;
  transition-duration: 0.5s;
  padding-left: 24px;

  a {
    color: white;
    font-weight: 300;
    letter-spacing: 1px;
    display: block;
    font-size: 32px;
    text-align: left;
    width: 100%;
    margin-bottom: 12px;
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }

  &.open {
    position: fixed;
    left: 0;
  }
}

.mobile-navigation-backdrop.open {
  transition-duration: 0.5s;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.44);
  z-index: 997;
}

.desktop-navigation {
  a {
    opacity: 0.92;

    &.logo {
      opacity: 1;
    }
  }

  @each $link in $nav-links {
    .#{$link} {
      a.#{$link} {
        opacity: 1;
        font-weight: 600;
        color: #1ca6f4;
        position: relative;

        &:after {
          content: '';
          display: block;
          position: absolute;
          width: 90%;
          height: 2px;
          left: 5%;
          bottom: -4px;
          background-color: #1ca6f4;
        }
      }
    }
  }
}

.btn12 {
  height: 60px;
  position: relative;
  width: 50px;
  float: right;

  .icon {

    @include icon(4px, 30px);

    &:before {
      @include icon-before(4px, 30px, -10px);
    }

    &:after {
      @include icon-after(4px, 30px, 10px);
    }
  }

  &.open {

    .icon {
      transition-duration: 0.5s;
      background: transparent;

      &:before {
        transform: rotateZ(45deg) scaleX(1.25) translate(6.5px, 6.5px);
      }

      &:after {
        transform: rotateZ(-45deg) scaleX(1.25) translate(6px, -6px);
      }
    }
  }

  &:hover {
    cursor: pointer;
  }
}

body .btn12 .icon {
  position: absolute;
  width: 30px;
  top: 25px;
}

@media (min-width: 991px) {
  body.navbar-fixed {
    padding-top: 76px;
  }
}

@media (max-width: 991px) {
  body {
    padding-top: 60px;
  }

  .mobile-top-bar {
    top: 0;
    position: fixed;
  }
}

@media (max-width: 1201px) {
  .desktop-navigation a {
    margin-left: 6px !important;
    padding: 0 4px !important;
  }
}