.about-us {
  .carousel-indicators {
    bottom: 5px;
  }
}

.aboutus-section {
  background: #fbfbfb;
  border-top: 1px solid #f5f5f5;
  border-bottom: 1px solid #f5f5f5;
}

@media (max-width: 576px) {
  .about-us{
    .container{
      padding-left: 32px;
      padding-right: 32px;
    }
  }
}