$font-size-base: 18px;

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.carousel-wrapper {
  display: flex;
  justify-content: center;
}

.carousel-wrapper .carousel {
  max-width: 1920px;
  width: 100%;
}

.carousel-control-prev:before,
.carousel-control-next:before {

  opacity: 1 !important;
  font-size: 24px;
  line-height: 36px;
  z-index: 3;
  text-shadow: none;
  width: 42px;
  height: 42px;
  border-radius: 100%;
  background-color: white;
  box-shadow: 2px 0px 4px rgba(0, 0, 0, 0.33);
  content: '';
}

.carousel-control-next:before {
  margin-right: 4px;
}

.carousel-control-prev:before {
  margin-left: 4px;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  filter: invert(1);
  position: absolute;
  z-index: 5;
  width: 1.4rem;
  height: 1.4rem;
}

.carousel-indicators [data-bs-target] {
  width: 36px;
  height: 5px;
  border-top: 0;
  border-bottom: 0;
}

.carousel-indicators .active {
  background: #1ca6f4
}

.btn.btn-default {

  border-radius: 0;
  padding: 10px 20px;
  color: #ffffff;
  border: 1px solid #000;
  font-weight: 300;
  margin-top: 12px;
  font-size: 16px;

  &.black {
    background: #1a1a1a;
  }
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Roboto", "Helvetica Neue", sans-serif;
  font-size: 14px;
  line-height: 1.428571429;
  color: #333;
  background-color: #fff;
}

a {
  color: #333;
}

p {
  font-size: 18px;
  line-height: 32px;

  &.p-link a {
    text-decoration: none;
    color: #212529;
  }
}

.figure-caption {
  font-size: 15px;
}

.large-h1 {
  font-size: 4rem;
}

.py-xl {
  padding-top: 5rem !important;
  padding-bottom: 5rem !important;
}

.no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@media (min-width: 767px) {
  .carousel-control-next {
    padding-left: 64px;
  }

  .carousel-control-prev {
    padding-right: 64px;
  }

}

.dropdown{
  display: inline;
  height: 46px;

  .dropdown-menu{
    min-width: 300px;  
  }
}

.dropdown .dropdown-menu .dropdown-item {
  line-height: 36px;
}

.search-container {
  label {
    display: none;
  }

  &.side-by-side {

    display: inline-block;

    input.search_bar {
      margin-bottom: 0;
    }
  }

  input.search_bar {
    border: none;
    outline: none;
    width: 36px;
    margin-bottom: 34px;
    font-size: 16px;
    padding: 12px 44px 12px 12px;
    transition: width 0.3s cubic-bezier(0, 0, 0.5, 1.5);
    background: rgb(236, 240, 241) url(https://i.imgur.com/seveWIw.png) no-repeat center center;

    &:hover {
      cursor: pointer;
    }
  }

  input.search_bar:focus {
    width: 275px;
    background-position: calc(100% - 25px) center
  }

  input.search_bar:focus:hover {
    cursor: text;
  }

  input[type=search]::-webkit-search-cancel-button {
    -webkit-appearance: none;
  }

  ::-webkit-input-placeholder {
    color: #0d2840;
    opacity: .5;
  }

  :-moz-placeholder {
    color: #0d2840;
    opacity: .5;
  }

  ::-moz-placeholder {
    color: #0d2840;
    opacity: .5;
  }

  :-ms-input-placeholder {
    color: #0d2840;
    opacity: .5;
  }
}


@media (max-width: 992px) {

  .projects.container,
  .our-team.container,
  .our-team>.container,
  .contact-page .container,
  footer .container {
    max-width: 96%
  }

}

@media (max-width: 576px) {

  .projects.container,
  .our-team.container,
  .our-team>.container,
  .contact-page .container,
  footer .container {
    max-width: 96%
  }
}

.dropdown {
  .dropdown-menu {
    background: white;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.33);
    border-radius: 0;
    border: 1px solid #ccc;
  }

  .dropdown-item:hover {
    cursor: pointer !important;
  }
}

@media (max-width: 1124px) {
  .dropdown {
    .btn-secondary.dropdown-toggle {
      font-size: 18px;
      background: white;
      border: 1px solid #ccc;
      border-radius: 0;
      color: #1a1a1a;
    }

    .dropdown-menu {
      width: 100%;
      background: white;
      box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.33);
      border-radius: 0;
      border: 1px solid #ccc;

      .dropdown-item {
        color: #1a1a1a;
        font-size: 18px;

        &:hover {
          color: #1ca6f4;
          cursor: pointer !important;
        }

        // Add the following to highlight the active filter
        &.active {
          opacity: 1;
          font-weight: 600;
          color: #1ca6f4;
          position: relative;
          background: white;
        }
      }
    }
  }
}