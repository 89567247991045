.mapboxgl-ctrl-attrib{
    display: none;
}

.contact-page{
    header{
        max-width: 1920px;
    }
    p{
        line-height: 28px;
    }

}