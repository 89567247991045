.partners {
  .carousel-indicators {
    bottom: 5px;
  }

  .partners-content {
    h2 {
      margin-bottom: 24px;
    }

    img {
      max-width: 140px;
      max-height: 80px;
    }

    >hr {
      margin: 36px 0;
    }

    .row {
      .col-sm-9 {
        >div:first-of-type {
          .partner-spacing {
            margin-bottom: 0;
          }
        }
      }
    }

    .partner-spacing {
      margin-bottom: 48px;
    }
  }
}



